import React from "react"
import Cathero from "../components/Category-hero"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import "../css/aboutus.css"
import Aboutsafe from "../components/about-safehand"
import Aboutethos from "../components/about-ethos"
import parser from "react-html-parser"

const Aboutus = ({ data }) => {
  let succeedlms = data.wordpressPage
  let yoast = data.wordpressPage.yoast_head
  return (
    <>
      <Layout>
        <SEO title={{ yoast }} />
        {/* Hero Section  */}
        <section
          className="financial-hero succeedlms-main aboutus"
          id="financial-hero"
        >
          <Cathero
            data={succeedlms.acf.title}
            img={
              succeedlms.acf.image.localFile.childImageSharp.fluid.originalImg
            }
            tabimage={
              succeedlms.acf.tablet_image.localFile.childImageSharp.fluid
                .originalImg
            }
            mobimage={
              succeedlms.acf.mobile_image.localFile.childImageSharp.fluid
                .originalImg
            }
            desc={succeedlms.acf.description}
            subtitle={succeedlms.acf.subtitle}
            wordpress_id={succeedlms.wordpress_id}
          />

          <hr />
        </section>

        <section className="about-section" id="about-section">
          <div className="container">
            <h3>About Us</h3>

            <div className="col-lg-12 col-md-12 col-sm-12 aboutdesc">
              {parser(succeedlms.acf.about_desc)}
            </div>
          </div>
          <hr />
        </section>

        <Aboutsafe node={succeedlms} />

        <section className="about-vision" id="about-vision">
          <div className="container">
            <div className="row mission">
              <div className="col-lg-6 col-md-12 col-sm-12 text-center text-lg-left order-1 order-lg-2 d-none d-lg-block d-md-block">
                <img
                  src={
                    succeedlms.acf.our_mission_img.localFile.childImageSharp
                      .fluid.originalImg
                  }
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 text-center text-lg-right order-2 order-lg-1 my-auto mx-auto missec">
                <h5>Our Mission</h5>
                {parser(succeedlms.acf.our_mission_desc)}
              </div>
            </div>

            <div className="row vision">
              <div className="col-lg-6 col-md-12 col-sm-12 text-center text-lg-right d-none d-lg-block d-md-block">
                <img
                  src={
                    succeedlms.acf.our_vision_img.localFile.childImageSharp
                      .fluid.originalImg
                  }
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 text-center text-lg-left my-auto mx-auto missec">
                <h5>Our Vision</h5>
                {parser(succeedlms.acf.our_vision_desc)}
              </div>
            </div>
          </div>
          <hr />
        </section>

        <Aboutethos node={succeedlms} />
      </Layout>
    </>
  )
}

export default Aboutus

export const succeedlmsquery = graphql`
  {
    wordpressPage(title: { eq: "About Us" }) {
      yoast_head
      wordpress_id
      acf {
        clients_title
        title
        subtitle
        description
        image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        tablet_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        mobile_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        about_desc
        about_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        safe_hand_desc_1
        safe_hand_img_1 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        safe_hand_desc_2
        safe_hand_img_2 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        safe_hand_desc_3
        safe_hand_img_3 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        safe_hand_desc_4
        safe_hand_img_4 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        our_mission_desc
        our_mission_img {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        our_vision_desc
        our_vision_img {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        ethos_desc_1
        ethos_img_1 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        ethos_desc_2
        ethos_img_2 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        ethos_desc_3
        ethos_img_3 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
      }
    }
  }
`
