import React from "react"
import { Link } from "gatsby"
import parser from "react-html-parser"

const Aboutethos = ({ node }) => {
  return (
    <>
      <section className="about-etous" id="about-ethos">
        <div className="container ethossec text-center mt-5 d-none d-sm-block">
          <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-399.png" />
        </div>

        <div className="container ethossec text-center mt-5 d-lg-none d-md-none">
          <div className="row">
            <div className="col-md-12 mb-4">
              <h4 className="title">Our Ethos</h4>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-4 pb-4">
              <img
                src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-408-1.png"
                alt="Centricity"
                style={{ margin: "2px" }}
              />
              <h5 className="customer-class">Customer-Centricity</h5>
            </div>
            <div className="col-md-4 pb-4">
              <img
                src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-391-1.png"
                alt="Flexibility"
                style={{ margin: "2px" }}
              />
              <h5 className="flexible-class">Flexibility</h5>
            </div>
            <div className="col-md-4 pb-4">
              <img
                src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-395-1.png"
                alt="Accountability"
                style={{ margin: "2px" }}
              />
              <h5 className="account-class">Accountability</h5>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Aboutethos
