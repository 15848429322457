import React from "react"
import { Link } from "gatsby"
import parser from "react-html-parser"

const Aboutsafe = ({node}) => {
    return (
        <>
            <section className="safe-hand-section" id="about-safe-hand">
                <div className="container">
                    <h3>You are in safe hands!</h3>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center">
                            <div class="card border-0">
                                <img class="card-img-top" src={node.acf. safe_hand_img_1.localFile
                                .childImageSharp.fluid.originalImg} alt="Card image cap"/>
                                    <div class="card-body aboutsec1">
                                    {parser(node.acf.safe_hand_desc_1)}
                                    </div>
                            </div>
                         </div>
                         <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center">
                            <div class="card border-0">
                                <img class="card-img-top" src={node.acf. safe_hand_img_2.localFile
                                .childImageSharp.fluid.originalImg} alt="Card image cap"/>
                                    <div class="card-body aboutsec2">
                                        {parser(node.acf.safe_hand_desc_2)}
                                    </div>
                            </div>
                         </div>
                         <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center">
                            <div class="card border-0">
                                <img class="card-img-top" src={node.acf. safe_hand_img_3.localFile
                                .childImageSharp.fluid.originalImg} alt="Card image cap"/>
                                    <div class="card-body aboutsec3">
                                        {parser(node.acf.safe_hand_desc_3)} 
                               </div>
                            </div>
                         </div>
                         <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center">
                            <div class="card border-0">
                                <img class="card-img-top" src={node.acf. safe_hand_img_4.localFile
                                .childImageSharp.fluid.originalImg} alt="Card image cap"/>
                                    <div class="card-body aboutsec4">
                                        {parser(node.acf.safe_hand_desc_4)}
                                    </div>
                            </div>
                         </div>
                    </div>
                </div>
                <hr/>
            </section>
        </>
    )
}

export default Aboutsafe